import React, { memo, useCallback } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoMobile } from 'assets/images/logo-mobile.svg';
import { ROUTES } from 'constants/routes';
import { NAVBAR_HEIGHT, NAVBAR_Z_INDEX } from 'common/theme';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { UserAtom } from 'atoms/user';
import UserPopper from 'components/common/UserPopper';
import BackButton from 'components/common/BackButton';
import { logoutUser } from 'utils/user';
import { CURRENT_ENV } from 'common/config';
import { ModeAtom } from 'atoms/mode';
import { setColorTheme } from 'utils/dom';

import './index.scss';

function Navbar({ toolLink }) {
  const isDesktop = useIsDesktop();
  const user = useRecoilValue(UserAtom);
  const [mode, setMode] = useRecoilState(ModeAtom);
  const LogoComponent = isDesktop ? Logo : LogoMobile;
  const isLight = mode === 'light';
  const loggedIn = !!user?.email;

  const toggleMode = useCallback(() => {
    setMode(prev => {
      const newMode = prev === 'light' ? 'dark' : 'light';
      setTimeout(() => setColorTheme(newMode), 0);
      return newMode;
    });
  }, [setMode]);

  const rootColor = toolLink ? 'secondary' : 'primary';
  const { Icon, text } = toolLink || {};
  const toolButton = toolLink ? (
    <Button key="tool" color="primary">
      <Icon />
      &nbsp;
      <Box fontWeight={600}>{text}</Box>
    </Button>
  ) : null;

  return (
    <Box width="100%">
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height={NAVBAR_HEIGHT}
        bgcolor={isLight ? 'neutral.n700' : 'neutral.n600'}
        boxShadow={3}
        zIndex={NAVBAR_Z_INDEX}
      >
        <Box position="relative" width="100%">
          <Container maxWidth="md">
            <Box height={NAVBAR_HEIGHT} display="flex" alignItems="center">
              <BackButton />
              <Breadcrumbs aria-label="breadcrumb">
                <Button
                  key="home"
                  component={Link}
                  to={ROUTES.dashboard}
                  color={rootColor}
                >
                  <AppsRoundedIcon />
                  &nbsp;
                  <Box fontWeight={600}>Tools</Box>
                </Button>
                {toolButton}
              </Breadcrumbs>
            </Box>
          </Container>
        </Box>
        <Box
          position="absolute"
          top={0}
          right={0}
          p={0}
          px="5px"
          height={NAVBAR_HEIGHT}
          display="flex"
          alignItems="center"
        >
          {loggedIn && <UserPopper user={user} logout={logoutUser} />}
          <Box
            ml="4px"
            className="navbar-logo"
            onClick={toggleMode}
            height={40}
          >
            <LogoComponent height={40} />
          </Box>
        </Box>
        {isDesktop && (
          <Box position="absolute" top={0} left={0} p="6px">
            <Box
              p="5px"
              borderRadius="4px"
              bgcolor="neutral.n800"
              fontSize={24}
              lineHeight={1}
              fontWeight={600}
              color="primary.main"
              style={{ userSelect: 'none' }}
              border={2}
            >
              {CURRENT_ENV}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  toolLink: PropTypes.shape({
    to: PropTypes.string,
    icon: PropTypes.node,
    text: PropTypes.string,
  }),
};

export default memo(Navbar);
